<main>
 
    <div id="wrapper">


<!--  de changecolor on scroll
    <div class="rowc grey ">
        <h1 class="logos black startLogo">Vertical Logo</h1> 
        </div>
        
        <div class="rowc blue">
        <h1 class="logos white altLogo">Vertical Logo</h1> 
        </div> -->
    
        <div id="c"></div>

    <section  id="menu1" class="spacer">  

        <div id="xsection" class="sectionwrap">
            
    <div id="box4" class="box4 active"  ><h1><span class="colorthe">Wij zijn een Rotterdams digitaal bureau</span></h1></div>
        </div>
        <div id="demo"></div>
</section>

<section  id="case" class="section1 color" data-color="#000000">
        
    <div class="sectionwrap">
    <div id="cases" class="sectionwrapmall">
    <div id="box7" class="box7 active"  ><h3 ><span class="colorthe">RESENT CASES</span></h3></div></div>




        <div id="wrapperleft">

  


            <div id="content" >
                <ul  id="menu">
                <li class="element"  data-name="/assets/img/blancgo.jpg" ><a href="#case" (click)="onTabClick(0)"><h3>BLANCGO</h3></a></li>
                <li class="element" data-name="/assets/img/enforz1.jpg" ><a href="#case" (click)="onTabClick(1)"><h3>ENFORZ</h3></a></li>
                <li class="element" data-name="/assets/img/winetank.jpg" ><a href="#case" (click)="onTabClick(2)"><h3>WINETANK</h3></a></li>
                <li class="element" data-name="/assets/img/mosterdenco.jpg" ><a href="#case" (click)="onTabClick(3)"><h3>MOSTERDENCO</h3></a></li>
                <li class="element" data-name="/assets/img/voordewijk.jpg" ><a href="#case" (click)="onTabClick(4)"><h3>VOORDEWIJK</h3></a></li>
                <li class="element" data-name="/assets/img/groosman.jpg" ><a href="#case" (click)="onTabClick(5)"><h3>GROOSMAN</h3></a></li>
                <li class="element" data-name="/assets/img/nextiabr1.jpg" ><a href="#case" (click)="onTabClick(6)"><h3>IABR- WHAT'S NEXT</h3></a></li>
                <li class="element" data-name="/assets/img/grootzus1.jpg" ><a href="#case" (click)="onTabClick(7)"><h3>GROOTZUS</h3></a></li>
                <li class="element" data-name="/assets/img/buurtcirkel.jpg" ><a href="#case" (click)="onTabClick(8)"><h3>BUURTCIRKEL</h3></a></li>
                <li class="element" data-name="/assets/img/vfi.jpg" ><a href="#case" (click)="onTabClick(9)"><h3>VFI REGISTER</h3></a></li>
                  <li class="element" data-name="/assets/img/voidsurbanism.jpg" ><a href="#case" (click)="onTabClick(10)"><h3>VOIDS URBANISM</h3></a></li>
                </ul>
            </div>
        
   
            </div>
            
    <div id="wrapperright">
        <div id="contents" ></div>
        <!--
        <div id="slickcircle">
 <div id="slickwrap"> 
        <ngx-slick-carousel class="carousel" 
        #slickModal="slick-carousel" 
        [config]="slideConfig" 
        (init)="slickInit($event)"
        (breakpoint)="breakpoint($event)"
        (afterChange)="afterChange($event)"
        (beforeChange)="beforeChange($event)">
        
        
        <div ngxSlickItem class="slide">
         
            <div class="wrapslide">
            <div id="two"><img src="assets/img/winetank10.jpg"></div>
            <div id="three"><img src="assets/img/winetank1.jpg"></div>
        </div>
        </div>
        
        <div ngxSlickItem class="slide">
         
   <div class="wrapslide">
            <div id="two"><img src="assets/img/enforz1.jpg"></div>
            <div id="three"><img src="assets/img/enforz2.jpg"></div>
           </div>
        </div>
        </ngx-slick-carousel>
    </div>
<div id="circelwrap">
        <circle-progress
        [percent]="100"
        [radius]="100"
        [outerStrokeWidth]="2"
        [innerStrokeWidth]="8"
        [outerStrokeColor]="'#ffffff'"
        [innerStrokeColor]="'#A4AFB5'"
        [animation]="true"
        [animationDuration]="8000"
        (click)="slickModal.slickNext()"
      ></circle-progress>
        </div>
    </div>
-->
    </div>

  
  
 </div>
 <div id="popyup" class="popyup">
    <div class="creturn" style="margin-right: 16px;"><img src="assets/img/thinarrow-2.png"></div>
    <app-blancgo  *ngIf="tabIndex === 0"></app-blancgo>
    <app-enforz  *ngIf="tabIndex === 1"></app-enforz>
    <app-winetank  *ngIf="tabIndex === 2"></app-winetank>
    <app-mosterdenco  *ngIf="tabIndex === 3"></app-mosterdenco>
    <app-voordewijk  *ngIf="tabIndex === 4"></app-voordewijk>
    <app-groosman  *ngIf="tabIndex === 5"></app-groosman>
    <app-iabr  *ngIf="tabIndex === 6"></app-iabr>
    <app-grootzus  *ngIf="tabIndex === 7"></app-grootzus>
    <app-buurtcirkel  *ngIf="tabIndex === 8"></app-buurtcirkel>
    <app-vfi  *ngIf="tabIndex === 9"></app-vfi>
    <app-voidsurbanism  *ngIf="tabIndex === 10"></app-voidsurbanism>

 </div>
</section>
<section id="aboutus" class="section3 color" data-color="#000000">

    <div id="overons" class="sectionwrap">
        <div id="box13" class="box7 active"  ><h3 ><span class="colorthe">OVER ONS</span></h3></div>
        <div id="contactinfo">
        <div id="box11" class="box4 active"  ><p><span class="colorthe">
            Xwebdevelopment is een creatief Rotterdams bureau voor online diensten. Onze creatieve oplossingen worden geleid door de belangrijkste webdesign principes, zodat uw bedrijf de zaken krijgt die het verdient.
            We hebben meer dan 6 jaar ervaring met het aanbieden van diensten aan klanten in Nederland. Of u nu een nieuwe website lanceert of uw bestaande site aanpast, onze diensten zorgen ervoor dat uw bedrijf effectief communiceert met uw doelgroep.
            We werken zorgvuldig samen met onze klanten om websites en applicaties te maken die goed werken, resultaten boeken en een rendement op investering genereren.
            Onze creatieve gedrevenheid gecombineerd met technische expertise zorgt ervoor dat zaken online blijven bewegen.</span>
        </p>
  
        <p><span class="samenwerken">SAMENWERKEN</span></p>
        <p><span class="colorthe">
            Ben je grafisch-ontwerper, illustrator of online marketeer en heb je interesse om samen te werken. Wil je een eigen ontwerp omzetten naar een website. Neem contact met ons op om de mogelijkheden te bespreken. Xwebdevelopment staat altijd open voor leuke samenwerking en nieuwe ideeën. We streven ernaar om websites naar een optimaal users experience niveau te brengen.</span>
        </p>
    
       <div id="container"></div>  
       <div id="containers"></div>  
  

    
    </div>
<div id="box12" class="box4 active"  ><h3>Wat doen we? </h3> <ul>
    <li><p>Webedesign</p></li>
    <li><p>Graphic design</p></li>
    <li><p>Visuele identiteit</p> </li>
    <li><p>Front-end development</p></li>
    <li><p>Back-end development</p> </li>
    <li><p>Web Hosting</p> </li>
    <li><p>Wordpress</p> </li>
    <li><p>Drupal</p> </li>
    <li><p>Angular</p> </li>
    <li><p>Moodle</p> </li>
</ul> </div></div>
<!---
    <div class="holdMe">
        <div class="resizeMe"></div>
    </div>
-->


</div>

   <!---
<div class="idee">
  <div id="box8" class="box7 active"  ><h3 ><span class="colorthe">CONTACT</span></h3></div> 
</div>
<div id="idee2" class="idee idee2">

<lib-image-as-particles [imageUrl]="selectedUrl" touchAction="none"
            imageWidth="80%" imageHeight="500px" backgroundColor="transparent"
            horizontalAlignment="start" verticalAlignment="center">
            
</lib-image-as-particles>
</div>-->
<div id="ideewrap"></div>
</section>





<section id="contact" class="section2 color" data-color="#000000">
<div id="box8" class="box7 active"  ><h3 ><span class="colorthe">CONTACT</span></h3></div> 
    <div id="talk" class="sectionwrap"> 
        <div id="contactinfo">
        <div id="box9" class="box4 active"  ><h3><span class="colorthe">
   Wil je samenwerken aan een project, neem dan contact met ons op. <a href="mailto:info@xwebdevelopment.nl" >info@xwebdevelopment.nl</a></span>
</h3></div>
<div id="box10" class="box4 active"  ><h3><span class="colorthe">
    Volg mij voor regelmatige updates op Facebook en Instagram </span>
</h3></div></div>
<!---
    <div class="holdMe">
        <div class="resizeMe"></div>
    </div>
-->
</div>
</section>
</div>
</main>


